import React, { Component } from 'react';
import {Routes, Route, Outlet } from 'react-router-dom';

// Elements
import BackToTop from './elements/back-top';
import PageScrollTop from './elements/page-scroll-top';

// Layout
import Header from './layout/header';
import Footer from './layout/footer';

// All Pages Router
import Index from './pages/index';
import AboutUs from './pages/about-us';
import Team from './pages/team';
import Services from './pages/services';
import FormLogin from './pages/form-login';
import FormRegister from './pages/form-register';
import FormForgetPassword from './pages/form-forget-password';
import ContactUs from './pages/contact-us';
import Booking from './pages/booking';
import BlogGrid from './pages/blog-grid';
import Patient1 from './pages/patient1';
import Patient2 from './pages/patient2';
import Patient3 from './pages/blog-details';
import Error from './pages/error-404';
import Faq from './pages/faq';
import ServiceDetail from './pages/service-detail';
import { useAuth } from '../context/AuthContext';
import Cookies from 'js-cookie';
const token = Cookies.get("token");
// const AuthLayout = () => {
	
  
// 	return (
// 	  <>
// 		{token ? (
// 		<>
// 			<Header />
// 			<Outlet />
// 			<Footer />
// 		</>
// 		) : (
// 		  <div></div> // Redirigez vers la page de connexion si l'utilisateur n'est pas connecté
// 		)}
// 	  </>
// 	)
//   }
  
function ThemeLayout(){
	return(
		<>
			<Header />
			<Outlet />
			<Footer />
		</>
	);
}

class Markup extends Component {
  render() {
    return (
		<>
		{token?(
			<Routes>
			{/* public routes*/}
		   
			{/* <Route path='/form-login' element={<FormLogin />} />
			<Route path='/form-register' element={<FormRegister />} />
			<Route path='/form-forget-password' element={<FormForgetPassword />} />
			<Route path='/' element={<Index />} /> */}
			
		   
			{/* private routes*/}
  
			<Route element={<ThemeLayout/>}>
			<Route path='/form-login' element={<FormLogin />} />
			<Route path='/form-register' element={<FormRegister />} />
			<Route path='/form-forget-password' element={<FormForgetPassword />} />
  
				<Route path='/' element={<Index />} />
			  <Route path='/about-us' element={<AboutUs />} />
			  <Route path='/team' element={<Team />} />
			  <Route path='/Sensors' element={<Services />} />
			  {/* <Route path='/service-detail' element={<ServiceDetail />} /> */}
			  {/* <Route path='/faq' element={<Faq />} /> */}
			  <Route path='/contact-us' element={<ContactUs />} />
			  <Route path='/booking' element={<Booking />} />
			  <Route path='/Listes des Patients' element={<BlogGrid />} />
			  <Route path='/patient1' element={<Patient1 />} />
			  <Route path='/patient2' element={<Patient2 />} />
			  <Route path='/patient3' element={<Patient3/>} />
  
			  {/* <Route path='*' element={<Error />} /> */}
			</Route>
		
		  </Routes>
		):(
			<FormLogin />
		)}
		

		<PageScrollTop />
		<BackToTop />
	  </>

    );
  }
}



export default Markup;
