// AuthProvider.jsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { login } from '../lib/react-query/api';


const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Ajoutez un nouvel état pour suivre l'état de connexion

  const updateToken = (newToken) => {
    setToken(newToken);
    localStorage.setItem('token', newToken);
  };

  const logout = () => {
    setToken(null);
    localStorage.removeItem('token');
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
      setIsLoggedIn(true); // Mettre à jour l'état d'authentification
    }
  }, []);

  // Ajoutez une fonction pour gérer la connexion de l'utilisateur
  const handleLogin = async (credentials) => {
    try {
      const token = await login(credentials);
      updateToken(token);
      setIsLoggedIn(true); // Mettre à jour l'état d'authentification
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const value = {
    token,
    isLoggedIn,
    updateToken,
    logout,
    handleLogin,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
