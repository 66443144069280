import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Import Images
import testPic2 from "../../images/testimonials/pic2.jpg";

class AuthorProfile2 extends Component{
	render(){
		return(
			<>
				<div className="author-box blog-user mb-50">
					<div className="author-profile-info">
						<div className="author-profile-pic">
							<img src={testPic2} alt=""/>
						</div>
						<div className="author-profile-content">
							<div className="clearfix">
								<h3>Foulena bent Foulen</h3>
								<strong className="mb-20 "><span className="fs-5 fw-bold">Age :</span>  <span className="text-primary fs-6 fw-bold">50 ans </span> </strong>
								<br/>
								<strong className="mb-20 "><span className="fs-5 fw-bold">Date de naissance :</span>  <span className="text-primary fs-6 fw-bold">16 juin 1974</span> </strong>
								<br/>
								<strong className="mb-20 "><span className="fs-5 fw-bold">Sexe :</span>  <span className="text-primary fs-6 fw-bold">Femme</span> </strong>
								<br/>
								<strong className="mb-20 "><span className="fs-5 fw-bold">Situation :</span>  <span className="text-primary fs-6 fw-bold">Mariée</span></strong>
								<br/>
								<strong className="mb-20 "><span className="fs-5 fw-bold">Groupe Sanguin :</span>  <span className="text-primary fs-6 fw-bold">A+</span> </strong>
								<br/>
								<strong className="mb-20 ">
									<dl>
										<dt className="fs-5 fw-bold">les Médicaments :</dt>
											<dd className="text-primary fs-6 fw-bold text-center">- Médicament 1 (2/j)</dd>
											<dd className="text-primary fs-6 fw-bold text-center">- Médicament 2 (1/j)</dd>
									</dl>
								</strong>
							</div>
						</div>
					</div>
				</div>
				
			</>
		);
	}
}

export default AuthorProfile2;