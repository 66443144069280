import React, { Component } from 'react';

class appointmentForm extends Component{
	render(){
		return(
			<>
				
				<div className="appointment-form form-wraper">
					<h3 className="title">Prendre rendez-vous</h3>
					<form action="#">
						<div className="form-group">
							<select className="form-select form-control">
								<option defaultValue>Sélectionner le Service</option>
								<option defaultValue="1">One</option>
								<option defaultValue="2">Two</option>
								<option defaultValue="3">Three</option>
							</select>
						</div>
						<div className="form-group">
							<select className="form-select form-control">
								<option defaultValue>Sélectionner le Docteur</option>
								<option defaultValue="1">One</option>
								<option defaultValue="2">Two</option>
								<option defaultValue="3">Three</option>
							</select>
						</div>
						<div className="form-group">
							<input type="text" className="form-control" placeholder="Votre nom"/>
						</div>
						<div className="form-group">
							<input type="number" className="form-control" placeholder="Votre Numéro de téléphone"/>
						</div>
						<div className="form-group">
							<input type="date" className="form-control"/>
						</div>
						<button type="submit" className="btn btn-secondary btn-lg">Réserver maintenant </button>
					</form>
				</div>
			
			</>
		);
	}
}

export default appointmentForm;