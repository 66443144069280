import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./css/service.css";
import ReactApexChart from "react-apexcharts";
// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import { io } from "socket.io-client";
import axios from "axios";
import VideoStream from "./VideoStream";
const Services = () => {
  const [dataa, setData] = useState({ BPM: 12, Sp02: 0, temp: 0, ecg: 0 });
  const [TOKEN, setToken] = useState("");
  const [CHANNEL, setChannel] = useState("");
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "ECG",
        data: [23],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "ECG",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [ 
         
          (new Date(Date.now()).toLocaleTimeString('en-US', { hour12: false })).toString()
        ],
      },
    },
  });

  useEffect(() => {
    const getLastData = async () => {
      try {
        const res = await axios.get("https://api.e-cls.ovh/get");
        console.log("demkffjklbfk",res.data);
        setData({
          ...dataa,
          BPM: res.data.sensors.BPM,
          Sp02: res.data.sensors.Sp02,
          temp: res.data.sensors.temp,
          ecg: res.data.sensors.ecg,
        });
      } catch (error) {}
    };
    
    // const getAllData = async () => {
    //   try {
    //     const res = await axios.get("https://api.e-cls.ovh/getAllegc");
    //     console.log("all ecg data:",res.data);
    //     const ecgValues = res.data
    //       .map((item) => parseInt(item.ecg))
    //       .filter((value) => !isNaN(value));
    //     setChartData((prevState) => ({
    //       ...prevState,
    //       series: [{ name: "ECG", data: ecgValues }],
    //       options: {
    //         ...prevState.options,
    //         xaxis: {
    //           ...prevState.options.xaxis,
    //           categories: [...prevState.options.xaxis.categories, "New Month"],
    //         },
    //       },
    //     }));
    //   } catch (error) {}
    // };
    // getAllData();
    getLastData();
    console.log("njsdkldsnbksldhnsdkfi");
    const socket = io("https://api.e-cls.ovh"); // Replace 'https://api.e-cls.ovh' with your server URL
    socket.on("connection", () => {
      console.log("Connected to server");
    });

    socket.on("newTable", (data) => {
      setData((prevData) => ({
        ...prevData,
        BPM: data.sensors.BPM,
        Sp02: data.sensors.Sp02,
        temp: data.sensors.temp,
        ecg: data.sensors.ecg,
      }));
      console.log(data.sensors.ecg)
      setChartData(prevChartData => ({
        ...prevChartData,
        series: [{
          name: "ECG",
          data: [...prevChartData.series[0].data, parseInt(data.sensors.ecg)]
        }],
        options: {
          ...prevChartData.options,
          xaxis: {
            ...prevChartData.options.xaxis,
            categories: [...prevChartData.options.xaxis.categories,  (new Date(Date.now()).toLocaleTimeString('en-US', { hour12: false })).toString()]
          }
        }
      }));
  

    });
    
   
    
   
    
    
    

    // Clean up the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, [chartData]);
  useEffect(() => {
    console.log("Categories updated:", chartData.options.xaxis.categories);
    console.log("data updated" , chartData.series[0].data)
  }, [chartData]);
  return (
    <>
      <div className="page-content bg-white">
        <div className="banner-wraper">
          <div
            className="page-banner"
            style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>Sensors</h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-home"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>{" "}
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Sensors
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
            <img className="pt-img2 animate2" src={circleDots} alt="" />
            <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
          </div>
        </div>
        <div className="cards-4">
          <div className="card-1">
            <div className="icon-1">
              <i class="fa-solid fa-heart-pulse"></i>
            </div>
            <div className="content1">
              <span>BPM</span>
              <p>{dataa.BPM}</p>
            </div>
          </div>
          <div className="card-1">
            <div className="icon-1">
              <i class="fa-solid fa-heart-pulse"></i>
            </div>
            <div className="content1">
              <span>SP02</span>
              <p>{dataa.Sp02}</p>
            </div>
          </div>
          <div className="card-1">
            <div className="icon-1">
              <i class="fa-solid fa-temperature-low"></i>
            </div>
            <div className="content1">
              <span>TEMP</span>
              <p>{dataa.temp}</p>
            </div>
          </div>
          <div className="card-1">
            <div className="icon-1">
              <i class="fa-solid fa-heart-circle-bolt"></i>
            </div>
            <div className="content1">
              <span>ECG</span>

              <p>{dataa.ecg}</p>
            </div>
          </div>
        </div>
        <div id="chart">
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="line"
            height={350}
          />
        </div>
        <div>
          <div class="wrapper">
            <form class="form-signin">
              <h2 class="form-signin-heading">Please Token</h2>
              <input
                class="form-control"
                type="text"
                name="ma"
                placeholder="Type Chanel"
                onChange={(e) => {
                  setChannel(e.target.value);
                  console.log(CHANNEL);
                }}
              />
              <br/>
              <textarea
                class="form-control"
                type="text"
                name="ma"
                placeholder="Type Token"
                onChange={(e) => {
                  setToken(e.target.value);
                  console.log(TOKEN);
                }}
              />
            </form>
          </div>
        </div>
        <VideoStream CHANNEL={CHANNEL} TOKEN={TOKEN} />
      </div>
    </>
  );
};
export default Services;
