import React, { useEffect , useState } from 'react';
import './VideoStream.css';
import AgoraRTC from 'agora-rtc-sdk-ng';
import axios from "axios"
const VideoStream = ({CHANNEL , TOKEN}) => {
    
    // const generateToken = async () => {
    //     try {
    //         const response = await axios.get(`https://api.agora.io/dev/v1/token/rtc/immutable?appId=f322e6c8ba144c9ba295a54942091e61&uid=0&channelName=${CHANNEL}`);
    //         console.log(response.data.token)
    //         setToken(response.data.token);
    //     } catch (error) {
    //         console.error('Error generating token:', error);
    //     }
    // };
    useEffect(() => {
        try {
            const APP_ID = "f322e6c8ba144c9ba295a54942091e61";
            // const TOKEN = "007eJxTYIi4O9fKSedm2UIGJ4Ev05rnTku9wOIr1xXSIjnTxm6d7AkFhjRjI6NUs2SLpERDE5Nky6REI0vTRFMTSxMjA0vDVDPDuVNc0hoCGRkOnlrNysgAgSA+C0NuYmYeAwMAu3Udxg==";
            // const CHANNEL = "main";
            console.log(CHANNEL , TOKEN)
            const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
            let localTracks = [];
            let remoteUsers = {};

            const joinAndDisplayLocalStream = async () => {
                client.on('user-published', handleUserJoined);
                client.on('user-left', handleUserLeft);
                const UID = await client.join(APP_ID, CHANNEL, TOKEN, null);
                localTracks = await AgoraRTC.createMicrophoneAndCameraTracks();

                const player = `<div class="video-container" id="user-container-${UID}">
                                    <div class="video-player" id="user-${UID}"></div>
                                </div>`;
                document.getElementById('video-streams').insertAdjacentHTML('beforeend', player);
                localTracks[1].play(`user-${UID}`);
                await client.publish([localTracks[0], localTracks[1]]);
            };

            const joinStream = async () => {
                if (client.connectionState === 'DISCONNECTED') {
                    await joinAndDisplayLocalStream();
                    document.getElementById('join-btn').style.display = 'none';
                    document.getElementById('stream-controls').style.display = 'flex';
                } else {
                    console.log('Client is already in connecting/connected state');
                }
            };

            const handleUserJoined = async (user, mediaType) => {
                remoteUsers[user.uid] = user;
                await client.subscribe(user, mediaType);

                if (mediaType === 'video') {
                    let player = document.getElementById(`user-container-${user.uid}`);
                    if (player != null) {
                        player.remove();
                    }

                    player = `<div class="video-container" id="user-container-${user.uid}">
                                    <div class="video-player" id="user-${user.uid}"></div> 
                                </div>`;
                    document.getElementById('video-streams').insertAdjacentHTML('beforeend', player);
                    user.videoTrack.play(`user-${user.uid}`);
                }

                if (mediaType === 'audio') {
                    user.audioTrack.play();
                }
            };

            const handleUserLeft = async (user) => {
                delete remoteUsers[user.uid];
                document.getElementById(`user-container-${user.uid}`).remove();
            };

            const leaveAndRemoveLocalStream = async () => {
                for (let i = 0; localTracks.length > i; i++) {
                    localTracks[i].stop();
                    localTracks[i].close();
                }

                await client.leave();
                document.getElementById('join-btn').style.display = 'block';
                document.getElementById('stream-controls').style.display = 'none';
                document.getElementById('video-streams').innerHTML = '';
            };

            const toggleMic = async (e) => {
                if (localTracks[0].muted) {
                    await localTracks[0].setMuted(false);
                    e.target.innerText = 'Mic on';
                    e.target.style.backgroundColor = 'cadetblue';
                } else {
                    await localTracks[0].setMuted(true);
                    e.target.innerText = 'Mic off';
                    e.target.style.backgroundColor = '#EE4B2B';
                }
            };

            const toggleCamera = async (e) => {
                if (localTracks[1].muted) {
                    await localTracks[1].setMuted(false);
                    e.target.innerText = 'Camera on';
                    e.target.style.backgroundColor = 'cadetblue';
                } else {
                    await localTracks[1].setMuted(true);
                    e.target.innerText = 'Camera off';
                    e.target.style.backgroundColor = '#EE4B2B';
                }
            };

            document.getElementById('join-btn').addEventListener('click', joinStream);
            document.getElementById('leave-btn').addEventListener('click', leaveAndRemoveLocalStream);
            document.getElementById('mic-btn').addEventListener('click', toggleMic);
            document.getElementById('camera-btn').addEventListener('click', toggleCamera);

            return () => {
                const joinBtn = document.getElementById('join-btn');
                const leaveBtn = document.getElementById('leave-btn');
                const micBtn = document.getElementById('mic-btn');
                const cameraBtn = document.getElementById('camera-btn');
        
                if (joinBtn) {
                    joinBtn.removeEventListener('click', joinStream);
                }
                if (leaveBtn) {
                    leaveBtn.removeEventListener('click', leaveAndRemoveLocalStream);
                }
                if (micBtn) {
                    micBtn.removeEventListener('click', toggleMic);
                }
                if (cameraBtn) {
                    cameraBtn.removeEventListener('click', toggleCamera);
                }
            
            };
        } catch (error) {
            console.log(error)
        }
    }, [CHANNEL, TOKEN]); // Run only once on component mount

    return (
        <div>
            
            <button id="join-btn">Join Stream</button>
            <div id="stream-wrapper">
                <div id="video-streams"></div>

                <div id="stream-controls">
                    <button id="leave-btn">Leave Stream</button>
                    <button id="mic-btn">Mic On</button>
                    <button id="camera-btn">Camera on</button>
                </div>
            </div>
        </div>
    );
};

export default VideoStream;
