import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Import Images
import logo from "../../images/logo.png";
import axios from 'axios';

const FormLogin = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };
	const handleRegister = async(e) =>{
		e.preventDefault();
		try {
			const res = await axios.post('https://api.e-cls.ovh/register' , {name , email , username , password});
			console.log(res.data);
			alert("user is registred successfully!!!")
		} catch (error) {
			console.log(error.response.data.msg)
		}
	}

    return (
        <>
            <div className="section-area account-wraper2">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-5 col-lg-6 col-md-8">
                            <div className="appointment-form form-wraper">
                                <div className="logo">
                                    <img src={logo} alt=""/>
                                </div>
                                <form onSubmit={handleRegister}>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Name" value={name} onChange={handleNameChange}/>
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control" placeholder="Email" value={email} onChange={handleEmailChange}/>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Username" value={username} onChange={handleUsernameChange}/>
                                    </div>
                                    <div className="form-group">
                                        <input type="password" className="form-control" placeholder="Password" value={password} onChange={handlePasswordChange}/>
                                    </div>	
                                    <div className="form-group">
                                        <button  type="submit" className="btn btn-primary w-100 radius-xl">Register Now</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>					
                </div>
            </div>
        </>
    );
}

export default FormLogin;
