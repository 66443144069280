import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import logo from "../../images/logo.png";
import { login } from '../../lib/react-query/api';
import axios from "axios"
import Cookies from "js-cookie"
const FormLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Utilise useNavigate pour la navigation

  const redirect = () => {
    console.log("Redirecting...");
    navigate('/'); // Redirige vers la page d'accueil
  };

  const { mutate, isLoading, isError, error } = useMutation(login, {
    onSuccess: () => {
      console.log("Login successful!");
      redirect(); // Redirection après une connexion réussie
    },
  });
  const handleLogin = async(e) => {
    e.preventDefault()
      try {
        const res = await axios.post('https://api.e-cls.ovh/login' , {username , password});
        console.log(res.data)
        Cookies.set("token" , res.data.accesstoken)
        window.location.href = "/"
      } catch (error) {
        console.log(error.response.data.msg)
      }
  };

  return (
    <>
      <div className="section-area account-wraper2">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-8">
              <div className="appointment-form form-wraper">
                <div className="logo">
                  <img src={logo} alt=""/>
                </div>
                <form onSubmit={handleLogin}>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn mb-30 btn-lg btn-primary w-100"
                     
                      disabled={isLoading}
                    >
                      {isLoading ? 'Logging in...' : 'Login'}
                    </button>
                    <Link to="/form-forget-password" data-toggle="tab">Forgot Password</Link>
                  </div>
                  {/* <div className="text-center mt-40">
                    <p className="mt-0">Don't have an account?</p>
                    <Link className="btn btn-lg btn-secondary w-100" data-toggle="tab" to="/form-register">Register</Link>
                  </div> */}
                </form>
                {isError && <div>Error: {error.message}</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormLogin;
